import { defineNuxtPlugin } from 'nuxt/app'
export default defineNuxtPlugin(() => {
  const script = document.createElement('script')
  script.src = 'https://bijbelgenootschap.involve.me/embed'
  script.onload = () => {
    if (window.brandquizEmbed) {
      window.brandquizEmbed.init()
    }
  }
  document.head.appendChild(script)
})
