import revive_payload_client_FwHKfIwnke from "/vercel/path0/bible-assistant/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.19.2_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_EP2dfQLjxg from "/vercel/path0/bible-assistant/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.19.2_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_4aVMCCvsZe from "/vercel/path0/bible-assistant/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.19.2_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_IeCCM8xBzV from "/vercel/path0/bible-assistant/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.19.2_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_QUwyBnDTjx from "/vercel/path0/bible-assistant/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.19.2_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LmxKqAtgoR from "/vercel/path0/bible-assistant/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.19.2_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_FJTSVNGQZI from "/vercel/path0/bible-assistant/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.19.2_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_8oXinzQoYR from "/vercel/path0/bible-assistant/node_modules/.pnpm/@pinia+nuxt@0.5.2_rollup@4.19.2_typescript@5.5.4_vue@3.4.35/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/bible-assistant/.nuxt/components.plugin.mjs";
import prefetch_client_arTccKRs8p from "/vercel/path0/bible-assistant/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@4.19.2_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_QAVHmv4JZr from "/vercel/path0/bible-assistant/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.12.4_rollup@4.19.2_vue@3.4.35/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_QWH6nb054Y from "/vercel/path0/bible-assistant/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_rollup@4.19.2_vue@3.4.35/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_XGQP2oSlra from "/vercel/path0/bible-assistant/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_rollup@4.19.2_vue@3.4.35/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_fSuRE4FZlf from "/vercel/path0/bible-assistant/node_modules/.pnpm/nuxt-bugsnag@7.3.0_rollup@4.19.2/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_Y4PKgyXkaQ from "/vercel/path0/bible-assistant/node_modules/.pnpm/@emanuele-em+nuxt-swipe@1.0.2_rollup@4.19.2/node_modules/@emanuele-em/nuxt-swipe/dist/runtime/plugin.mjs";
import feedbackForm_client_IRDRX8bbvo from "/vercel/path0/bible-assistant/plugins/feedbackForm.client.js";
export default [
  revive_payload_client_FwHKfIwnke,
  unhead_EP2dfQLjxg,
  router_4aVMCCvsZe,
  payload_client_IeCCM8xBzV,
  navigation_repaint_client_QUwyBnDTjx,
  check_outdated_build_client_LmxKqAtgoR,
  chunk_reload_client_FJTSVNGQZI,
  plugin_vue3_8oXinzQoYR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_arTccKRs8p,
  plugin_QAVHmv4JZr,
  switch_locale_path_ssr_QWH6nb054Y,
  i18n_XGQP2oSlra,
  plugin_fSuRE4FZlf,
  plugin_Y4PKgyXkaQ,
  feedbackForm_client_IRDRX8bbvo
]